import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { compose } from "redux";

import creditsWrapper from "components/EntityWrappers/creditsWrapper";
import CreatorViewAppearancesStructuredData from "pages/CreatorView/Appearances/CreatorViewAppearancesStructuredData";

import AppearanceGrid from "../../AppearanceGrid";

const loadingStyles = {
  noOverlay: {
    fontSize: "2rem",
    minHeight: "10rem",
    display: "flex",
    alignItems: "center",
  },
};

const CreatorViewAppearancesGridView = (props) => {
  const { creator } = props;
  return (
    <Fragment>
      <CreatorViewAppearancesStructuredData creator={creator} />
      <AppearanceGrid {...props} carouselAtSize={false} />
    </Fragment>
  );
};

CreatorViewAppearancesGridView.propTypes = {
  creator: PropTypes.instanceOf(Map),
};

CreatorViewAppearancesGridView.defaultProps = {
  creator: Map({}),
};

export default compose(
  creditsWrapper({
    suffix: "creator_appearances_grid",
    groups: [
      { field: "podcast_id", size: 9999 },
      { field: "role", size: 10 },
    ],
    noLoadingOverlay: true,
    noLoadingPadding: false,
    loadingStyles,
  })
)(CreatorViewAppearancesGridView);
