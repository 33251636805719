import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import AddAppearancesButton from "./AddAppearancesButton";
import CreatorCreditSubmissions from "./CreatorCreditSubmissions";
import { getPageTitle } from "./CreatorViewAppearancesStructuredData";
import LayoutSelection from "./LayoutSelection";
import CreatorViewAppearancesEpisodesView from "./Views/Episodes";
import CreatorViewAppearancesGridView from "./Views/Grid";

import { getQueries } from "utils/url";

import useList from "hooks/useList";
import { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  creatorViewAppearances: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%",
  },
  creatorViewAppearancesMobile: {
    flex: 1,
  },
  sorting: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "2rem",
    borderBottom: "1px #DFE1E6 solid",
    position: "relative",

    [ScreenSizes.mdAndBelow]: {
      paddingBottom: 0,
      borderBottom: 0,
    },
  },
  sortingDescription: {
    ...gStyles.avalonSemiBold,
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    marginRight: "1.5rem",
    fontSize: "1.25rem",
    color: "#000",

    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
  sortingDescriptionHasSubmissions: {
    [ScreenSizes.mdAndBelow]: {
      display: "flex",
      padding: "0 1rem",
      margin: "0",
      justifyContent: "center",
    },
  },
  sortingControls: {
    width: "12rem",
  },
  appearanceListMobile: {
    marginTop: "1rem",
  },
  appearanceList: {
    display: "flex",
    flexDirection: "column",
  },
  contributeButtonWrapper: {
    minWidth: "9em",
    display: "flex",
    alignItems: "center",

    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
  viewLinks: {
    display: "flex",
    alignItems: "center",

    [ScreenSizes.mdAndBelow]: {
      display: "block",
      position: "absolute",
      top: -50,
      right: "1rem",
      alignSelf: "center",
    },
  },
  viewLinkActive: {
    opacity: 1,
  },
  creditsDescription: {
    ...gStyles.fontLight,
    marginTop: "1rem",
    marginBottom: "1rem",
    padding: "0 0.75rem",

    [ScreenSizes.mdAndAbove]: {
      padding: 0,
      marginBottom: "0rem",
    },
  },
  creditsDescriptionCreatorName: {
    ...gStyles.fontBold,
    marginBottom: "1rem",
  },
};

const CreatorViewAppearances = (props) => {
  const { creator, mobile } = props;

  const location = useLocation();

  const { view } = getQueries(location.search);
  const isLoggedIn = useLoggedIn();

  const { styles } = useStyles(baseStyles, props);

  const myListKey = `creatorView/creator/${creator.get(
    "id"
  )}/credit_submissions`;
  const moderatorListKey = `creatorView/creator/${creator.get(
    "id"
  )}/credit_submissions/moderator`;
  const { total: myListTotal } = useList(myListKey);
  const { total: moderatorListTotal } = useList(moderatorListKey);

  const hasCreditSubmissions = myListTotal > 0 || moderatorListTotal > 0;

  return (
    <div
      data-id="creator-appearances-section"
      className={css(styles.creatorViewAppearances)}
    >
      {isLoggedIn && (
        <CreatorCreditSubmissions
          creator={creator}
          myListKey={myListKey}
          moderatorListKey={moderatorListKey}
        />
      )}
      <div className={css(styles.sorting)}>
        <h1
          className={css(
            styles.sortingDescription,
            hasCreditSubmissions && styles.sortingDescriptionHasSubmissions
          )}
          title={getPageTitle(creator.get("name"))}
        >
          {getPageTitle(creator.get(mobile ? "informal_name" : "name"))}
        </h1>

        <div className={css(styles.contributeButtonWrapper)}>
          <AddAppearancesButton creator={creator} />
        </div>
        <div className={css(styles.viewLinks)}>
          <LayoutSelection />
        </div>
      </div>
      <p className={css(styles.creditsDescription)}>
        Explore podcast episodes featuring{" "}
        <span className={css(styles.creditsDescriptionCreatorName)}>
          {creator.get("name")}
        </span>{" "}
        including guest interviews, hosted podcasts, and production roles.
      </p>
      {view === "grid" ? (
        <CreatorViewAppearancesGridView {...props} />
      ) : (
        <CreatorViewAppearancesEpisodesView {...props} />
      )}
    </div>
  );
};

CreatorViewAppearances.propTypes = {
  creator: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
};

CreatorViewAppearances.defaultProps = {
  creator: Map({}),
  mobile: false,
};

export default CreatorViewAppearances;
