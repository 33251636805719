import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import AccountLinks from "./AccountLinks";
import SideMenuFooter from "./SideMenuFooter";
import SideMenuItem from "./SideMenuItem";
import {
  logoLeft,
  logoTop,
  logoWidth,
  menuBackgroundColor,
  menuWidth,
  openMenuWidth,
} from "./SideMenuSettings";
import SideMenuUnlockPro from "./SideMenuUnlockPro";
import SocialMedia from "./SocialMedia";

import { selectAuthLoggedIn } from "selectors/auth";
import generateTransition from "utils/generateTransition";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import LayoutSizes from "styles/LayoutSizes";

const baseStyles = {
  wrapper__sidebar: {
    position: "relative",
    transition: generateTransition({
      target: "visual",
      targets: ["width", "max-width"],
      speed: "200ms",
    }),

    [LayoutSizes.hasDesktopMenu]: {
      overflow: "hidden",
      height: "100%",
      width: openMenuWidth,
      maxWidth: menuWidth,
    },
    [LayoutSizes.hasMobileOrTabletMenu]: {
      width: "100%",
      height: "100%",
    },
  },
  navbarContainer: {
    background: menuBackgroundColor,
    boxShadow: "inset -0.1em 0 0.4em rgba(0,0,0,0.1)",
    position: "absolute",
    top: 0,
    height: "100%",
    zIndex: 15,
    width: openMenuWidth,
    maxWidth: 0,
    transition: generateTransition({
      target: "visual",
      targets: ["width", "max-width"],
      speed: "200ms",
    }),

    [LayoutSizes.hasMobileOrTabletMenu]: {
      maxWidth: "none",
      width: "100%",
    },
  },
  navbar: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: "transparent",
    transition: generateTransition({ target: "visual", speed: "150ms" }),
    width: "auto",
    maxWidth: "100%",
    overflow: "auto",
    // Hide ScrollBar
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  marginTop: {
    marginTop: "2rem",
  },
  logoContainer: {
    display: "block",
    position: "absolute",
    width: logoWidth,
    margin: `${logoTop}px 0 10px ${logoLeft}px`,
  },
  logo: {
    top: 0,
    position: "absolute",
    display: "block",
    width: "100%",
    height: 0,
    paddingBottom: "100%",
  },
  logoImage: {
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  navitems: {
    [LayoutSizes.hasDesktopMenu]: {
      marginTop: 85,
    },
  },
  lock: {
    position: "absolute",
    fontSize: "0.5em",
    right: -8,
    textShadow: "0 0.1em 0.1em rgba(0,0,0,1)",
    bottom: -1,
    opacity: 0,
  },
  navDivider: {
    height: 32,
    [LayoutSizes.hasMobileOrTabletMenu]: {
      height: 24,
    },
  },
  footer: {
    textAlign: "center",
    padding: "1rem",
    borderTop: "1px solid rgba(255,255,255,0.2)",
    marginTop: "2rem",
  },
};

const openStyles = {
  wrapper__sidebar: {
    transition: generateTransition({
      target: "visual",
      targets: ["width", "max-width"],
      speed: "150ms",
      delay: "50ms",
    }),

    [LayoutSizes.hasDesktopMenu]: {
      maxWidth: openMenuWidth,
    },
  },
  navbarContainer: {
    transition: generateTransition({
      target: "visual",
      targets: ["width", "max-width"],
      speed: "150ms",
      delay: "50ms",
    }),

    [LayoutSizes.hasDesktopMenu]: {
      maxWidth: openMenuWidth,
    },
  },
  navbar: {
    color: "white",
    transition: generateTransition({ target: "visual", speed: "150ms" }),
  },
};

const collapsedStyles = {
  wrapper__sidebar: {
    [LayoutSizes.hasDesktopMenu]: {
      maxWidth: 0,
    },
  },
};

const SideMenu = (props) => {
  const { isOpen, onToggleOpen } = props;

  const { isLoggedIn } = useReduxState(
    (state) => ({
      isLoggedIn: selectAuthLoggedIn(state),
    }),
    []
  );

  const hasPro = useUserHasPro();

  const { styles } = useStyles(
    [baseStyles, isOpen && openStyles, !isOpen && collapsedStyles],
    props
  );

  const accountLinks = AccountLinks();
  const { isWindowSizeOrLess } = useWindowSize();
  const isLargeOrLess = isWindowSizeOrLess("large");

  const NAV_ITEMS = useMemo(
    () => [
      {
        id: "podcasts",
        title: "Podcasts",
        subMenu: [
          {
            id: "bePodcasts",
            title: "Best Podcasts",
            route: "/podcasts/ranking",
          },
          {
            id: "newpodcasts",
            title: "New Podcasts",
            route: "/podcasts/new",
          },
          {
            id: "podcastcharts",
            title: "Podcast Charts",
            route: "/charts/apple/us/top podcasts",
          },
          {
            id: "bestepisode",
            title: "Best Episodes",
            route: "/episodes",
          },
          {
            id: "addpodcast",
            title: "Add a Podcast",
            route: "/add",
          },
          {
            id: "claimpodcast",
            title: "Claim a Podcast",
            route: "/claim",
          },
        ],
      },
      {
        id: "features",
        title: "Features",
        subMenu: [
          {
            id: "creators",
            title: "Podcast Credits",
            route: "/creators",
          },
          {
            id: "networks",
            title: "Podcast Networks",
            route: "/networks",
          },
          {
            id: "lists",
            title: "Podcast Lists",
            route: "/lists",
          },
          {
            id: "monitoring",
            title: "Podcast Monitoring",
            route: "/alerts",
          },
          {
            id: "sponsors",
            title: "Podcast Sponsors",
            route: "/brands",
          },
          {
            id: "contacts",
            title: "Podcast Contacts",
            route: "https://features.podchaser.com/contacts/",
            external: true,
          },
        ],
      },
      {
        id: "solutions",
        title: "Solutions",
        subMenu: [
          {
            id: "pro",
            title: "Podchaser Pro",
            route: "https://features.podchaser.com/pro/",
            external: true,
          },
          {
            id: "api",
            title: "Podchaser API",
            route: "/advertise",
            external: false,
          },
          {
            id: "alerts",
            title: "Podchaser Alerts",
            route: "/alerts",
          },
          {
            id: "podcharts",
            title: "Podcharts",
            route:
              "https://podcharts.co/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
            external: true,
          },
          {
            id: "podroverReview",
            title: "Podrover Review",
            route:
              "https://www.podrover.com/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar",
            external: true,
          },
        ],
      },
      {
        id: "account",
        title: "Account",
        subMenu: accountLinks,
        exact: true,
      },
      {
        id: "company",
        title: "Company",
        subMenu: [
          {
            id: "about",
            title: "About",
            route: "/about",
          },
          {
            id: "integrations",
            title: "Integrations",
            route: "/integrations",
          },
          {
            id: "careers",
            title: "Careers",
            route: "/articles/jobs",
          },
          {
            id: "values",
            title: "Our Values",
            route: "/articles/inside-podchaser/our-values",
          },
        ],
      },
      {
        id: "resources",
        title: "Resources",
        subMenu: [
          {
            id: "helpCenter",
            title: "Help Center",
            route: "https://support.podchaser.com/en/",
            external: true,
          },
          {
            id: "community",
            title: "Community",
            route: "/leaderboard/reviews",
          },
          {
            id: "newsletter",
            title: "Newsletter",
            route: "https://podchaser.beehiiv.com/",
            external: true,
          },
          {
            id: "articles",
            title: "Blog",
            route: "/articles",
          },
        ],
      },
    ],
    [accountLinks]
  );

  const filteredNavItems = useMemo(
    () =>
      NAV_ITEMS.filter((item) => !!item)
        .filter((item) => !item.loggedInOnly || isLoggedIn)
        .filter((item) => !item.loggedOutOnly || !isLoggedIn),
    [NAV_ITEMS, isLoggedIn]
  );

  const handleSidebarClick = useCallback(
    (e) => {
      if (
        isLargeOrLess &&
        isOpen &&
        !e.target.hasAttribute("data-toggle-submenu")
      ) {
        onToggleOpen();
      }
    },
    [isLargeOrLess, isOpen, onToggleOpen]
  );

  const renderNavItem = (item, index) => {
    const key = `${item.id}-${index}`;

    if (item.id === "divider") {
      return <div key={key} className={css(styles.navDivider)} />;
    }

    return (
      <div key={key}>
        <SideMenuItem
          isOpen={isOpen}
          i={index}
          item={item}
          onClick={handleSidebarClick}
          hasPro={hasPro}
        />
      </div>
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={css(styles.wrapper__sidebar)}
      onClick={(e) => handleSidebarClick(e)}
      onKeyDown={(e) => eventIsFieldTrigger(e) && handleSidebarClick(e)}
    >
      <div className={css(styles.navbarContainer)}>
        <div
          className={css(styles.navbar, hasPro && styles.marginTop)}
          id="nav"
        >
          <div className={css(styles.header)}>
            {!hasPro && <SideMenuUnlockPro />}
            <nav className={css(styles.navitems)}>
              {filteredNavItems.map(renderNavItem)}
            </nav>
          </div>
          <SocialMedia />
          <SideMenuFooter />
        </div>
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  onToggleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

SideMenu.defaultProps = {
  isOpen: false,
};

export default memo(SideMenu);
