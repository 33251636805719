import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map, List } from "immutable";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";

import colours from "../../../styles/colours";
import ConfirmedGroup from "./ConfirmedGroup";
import PodcastGroup from "./PodcastGroup";
import RoleGroupContainer from "./RoleGroupContainer";

import { selectSpecificPodcast } from "selectors/podcast";
import slugify from "utils/misc/slugify";
import { commaListWithMore } from "utils/text/commaListWithMore";
import { truncateString } from "utils/truncate";

import useReduxState from "hooks/useReduxState";
import { useScrollHashIntoView } from "hooks/useSmoothScroll";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  creatorViewAppearanceList: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  },
  contentContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    border: "none",
  },
  subList: {
    ...gStyles.fontLight,
    color: colours.lightishGrey,
    fontSize: "1.5rem",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    width: "100%",
    border: "none",
  },
  subtitle: {
    ...gStyles.textEllipsis,
    display: "inline-block",
    marginRight: ".5rem",
  },
  subtitleDivider: {
    fontSize: ".2rem",
    margin: "0 .7rem",
    verticalAlign: "middle",
  },
  approveLink: {
    cursor: "pointer",
    fontSize: ".8em",
    ...gStyles.fontSemiBold,
  },
  approvingOverlay: {
    position: "absolute",
    backgroundColor: "rgb(255, 255, 255, 0.5)",
    width: "100%",
    height: "100%",
    zIndex: 20,
  },
};

const AppearanceGroup = (props) => {
  const {
    parentGroup,
    creditGroup,
    parentField,
    nextField,
    nextOption,
    nextGroup,
    count,
    field,
    children,
    isLast,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const [open, setOpen] = useState(true);
  const [approving] = useState(false);
  const appearanceRef = useRef(null);

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const podcast = useReduxState((state) => {
    if (field === "podcast_id" || parentField === "podcast_id") {
      const podcast_id =
        field === "podcast_id"
          ? creditGroup.get("podcast_id")
          : parentGroup.get("podcast_id");

      return selectSpecificPodcast(state, podcast_id);
    }

    return null;
  }, []);

  const roleGroup = parentGroup || creditGroup;
  const role = roleGroup && roleGroup.get("role");
  const roleCode = role ? slugify(role.get("code") || "host") : "";
  const hashId = parentGroup
    ? `${roleCode}-${creditGroup && creditGroup.get("podcast_id")}`
    : roleCode;

  const isTargeted = useScrollHashIntoView({
    hashId,
    elementRef: appearanceRef,
    isDisabled: mobile,
  });

  const handleToggle = useCallback((e) => {
    if (!e.which && !e.keyCode) {
      setOpen((prevOpen) => !prevOpen);
    }
  }, []);

  const roleText = useMemo(() => {
    if (!parentGroup) {
      return "";
    }
    if (parentGroup.getIn(["role", "code"]) === "voiceActor") {
      // actually get this from the current group so we only show characters for this podcast
      const rawCharacters = creditGroup.get("character");
      const characters =
        rawCharacters && commaListWithMore(rawCharacters.toJS());

      return (
        <Fragment>
          {parentGroup.getIn(["role", "title"])}
          {characters && <Fragment> ({characters})</Fragment>}
        </Fragment>
      );
    }
    if (parentGroup.getIn(["role", "code"]) === "other") {
      return parentGroup.getIn(["role", "role_title"]);
    }

    return parentGroup.getIn(["role", "title"]);
  }, [parentGroup, creditGroup]);

  const parentText = useMemo(() => {
    if (parentGroup) {
      if (parentField === "role") {
        return roleText;
      }
      if (parentField === "podcast_id") {
        return truncateString(podcast.get("title"), 20);
      }
      if (parentField === "confirmed") {
        return parentGroup.get("confirmed") ? "Confirmed" : "Not Confirmed";
      }
    }

    return "";
  }, [parentGroup, parentField, roleText, podcast]);

  const countText = useMemo(() => {
    if (nextField === "confirmed" && nextOption) {
      let text = "";
      const confirmed = nextGroup.getIn([0, "confirmed"]);
      const allCount = nextGroup.getIn([0, "count"]);

      if (confirmed > 0) {
        text = `${confirmed} Confirmed, ${text}`;
      }
      if (confirmed !== allCount) {
        text = `${allCount - confirmed} Not Confirmed, ${text}`;
      }

      return text;
    }
    if (nextField && nextOption) {
      const nextCount = nextGroup.size;

      return `${nextCount} ${pluralize(nextOption.label, nextCount)}`;
    }

    return `${count} ${pluralize("episode", count)}`;
  }, [nextField, nextOption, nextGroup, count]);

  const selectionDateText = useMemo(() => {
    if (field === "podcast_id") {
      const min = creditGroup.get("min_date");
      const max = creditGroup.get("max_date");
      const minYear = new Date(min).getFullYear();
      const maxYear = new Date(max).getFullYear();

      if (min && !max) {
        return minYear;
      }
      if (max) {
        if (min && minYear !== maxYear) {
          if (!Number.isNaN(minYear) && !Number.isNaN(maxYear)) {
            return `${minYear} - ${maxYear}`;
          }
          if (!Number.isNaN(minYear) && Number.isNaN(maxYear)) {
            return minYear;
          }
          if (Number.isNaN(minYear) && !Number.isNaN(maxYear)) {
            return maxYear;
          }
        } else if (!Number.isNaN(maxYear)) {
          return maxYear;
        }
      }
    }

    return "We're not sure when";
  }, [field, creditGroup]);

  const renderSubtitleDivider = useCallback(
    () => (
      <span className={css(styles.subtitleDivider)}>
        <FontAwesomeIcon icon={faCircle} />
      </span>
    ),
    [styles.subtitleDivider]
  );

  const renderSubtitle = useCallback(() => {
    if (nextField) {
      return <div className={css(styles.subtitle)}>{countText}</div>;
    }

    return (
      <LazyLoadComponent>
        <div className={css(styles.subtitle)}>
          {parentText}
          {renderSubtitleDivider()}
          {countText}
          {renderSubtitleDivider()}
          {selectionDateText}
        </div>
      </LazyLoadComponent>
    );
  }, [
    countText,
    nextField,
    parentText,
    renderSubtitleDivider,
    selectionDateText,
    styles.subtitle,
  ]);

  const fieldProps = useMemo(
    () => ({
      open,
      showOpenToggle: props.count > 3,
      nextField,
      nextOption,
      nextGroup,
      isLast,
      subtitle: renderSubtitle(),
      role: parentGroup && parentGroup.get("role"),
      isTargeted,
      toggleDataId: `${field}-appearance-group-toggle`,
      onToggle: handleToggle,
    }),
    [
      open,
      props.count,
      nextField,
      nextOption,
      nextGroup,
      isLast,
      renderSubtitle,
      parentGroup,
      isTargeted,
      field,
      handleToggle,
    ]
  );

  const renderHeading = () => {
    switch (field) {
      case "role":
        return (
          <RoleGroupContainer
            {...fieldProps}
            roleCode={creditGroup.getIn(["role", "code"])}
            roleTitle={creditGroup.getIn(["role", "role_title"])}
          >
            {children}
          </RoleGroupContainer>
        );
      case "podcast_id":
        return (
          <PodcastGroup {...fieldProps} podcast={podcast}>
            {children}
          </PodcastGroup>
        );
      case "confirmed": // TODO style up
        return (
          <ConfirmedGroup
            {...fieldProps}
            confirmed={creditGroup.get("confirmed")}
          >
            {children}
          </ConfirmedGroup>
        );
      default:
        return null;
    }
  };

  return (
    <div
      id={hashId}
      data-id={`${field}-appearance-group`}
      className={css(styles.creatorViewAppearanceList)}
      ref={appearanceRef}
    >
      {approving && <div className={css(styles.approvingOverlay)} />}
      <div className={css(styles.contentContainer)}>
        <div className={css(styles.subList)}>{renderHeading()}</div>
      </div>
    </div>
  );
};

AppearanceGroup.propTypes = {
  creditGroup: PropTypes.instanceOf(Map).isRequired,
  field: PropTypes.string,
  children: PropTypes.node,
  count: PropTypes.number,
  parentGroup: PropTypes.instanceOf(Map),
  parentField: PropTypes.string,
  nextField: PropTypes.string,
  nextOption: PropTypes.object,
  nextGroup: PropTypes.instanceOf(List),
  isLast: PropTypes.bool,
};

AppearanceGroup.defaultProps = {
  field: null,
  children: null,
  count: 0,
  parentGroup: null,
  parentField: null,
  nextField: null,
  nextOption: null,
  nextGroup: null,
  isLast: false,
};

export default AppearanceGroup;
