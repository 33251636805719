import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import SideSubMenuItems from "./SideSubMenuItems";

import generateTransition from "utils/generateTransition";
import sendGAEvent from "utils/sendGAEvent";

import useHover from "hooks/useHover";
import { useStyles } from "hooks/useStyles";
import { useUserPermission } from "hooks/useUserHasPermission";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const navItemStyles = {
  ...gStyles.avalonBold,
  display: "block",
  padding: "0.688rem 0rem",
  textDecoration: "none",
  color: "#fff",
  opacity: 0.8,
  fontSize: "1rem",
  borderLeft: "3px solid transparent",
  textTransform: "uppercase",
  overflow: "hidden",
  transition: generateTransition({ target: "visual", speed: "150ms" }),

  ...getHoverQuery({
    backgroundColor: "var(--color-secondary-d2)",
    opacity: 1,
  }),
};

const baseStyles = {
  navItem: {
    ...navItemStyles,
    borderLeftColor: colours.darkPurple,
  },
  navItemClosed: {
    opacity: 0.5,
  },

  navItemActive: {
    ...navItemStyles,
    ...gStyles.avalonBold,
    opacity: 1,
    boxShadow: "inset 0 0.1em 0.2em rgba(0,0,0,0.2)",
    ...gStyles.gradientBackground,
    ...gStyles.newBackgroundItemColor,
    borderLeftColor: "#1c0144",

    ...getHoverQuery({
      ...gStyles.gradientBackground,
      ...gStyles.newBackgroundItemColor,
      borderLeftColor: "#1c0144",
      color: colours.white,
    }),
  },
  navIcon: {
    fontSize: "1rem",
    pointerEvents: "none",
  },
  title: {
    pointerEvents: "none",
  },
  navIconClosed: {
    transform: "scale(1,1)",
  },
  navIconHover: {
    transform: "scale(1.2,1.2)",
    textShadow: "0 0.1em 0.2em rgba(0,0,0,0.5)",
  },

  navTitleContainer: {
    transition:
      "transform 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946) 200ms",
    display: "block",
    textTransform: "none",
    letterSpacing: "0.03em",
    overflow: "hidden",
    alignSelf: "center",
    opacity: 1,
    lineHeight: 1.5,
    verticalAlign: "middle",
  },
  navTitleContainerClosed: {
    [ScreenSizes.smAndAbove]: {
      opacity: 0,
      transform: "translateX(0)",
    },
  },
  navTitleContainerHover: {
    transform: "translateX(3px)",
    transition:
      "transform 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946)",
  },

  navTitle: {
    ...gStyles.avalonBold,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1.25rem",
    marginLeft: "0.3em",
    paddingLeft: "1em",
    marginRight: "1.7rem",
    border: "none",
    background: "transparent",
    width: "100%",
    cursor: "pointer",
    paddingRight: "2.2rem",
    paddingBottom: "1.5rem",
    boxSizing: "border-box",
    marginBottom: "-1.5rem",
  },
  closedList: {
    boxSizing: "border-box",
  },
  listSeperater: {
    height: "0.063rem",
    background: "rgb(8, 0, 43)",
    borderRadius: "0",
    marginTop: "0",
    margin: "1.5rem 1.5rem",
  },
  submenu: {
    ...gStyles.avalonBold,
    fontSize: "1rem",
  },
  addMargin: {
    marginBottom: "1.2rem",
    marginTop: "1.2rem",
  },
};

const SideMenuItem = (props) => {
  const { isOpen, item } = props;

  const { styles } = useStyles(baseStyles, props);
  const [open, setOpen] = useState(false);

  const userPermissions = useUserPermission();

  const [hoverRef, isHovered] = useHover();

  const handleItemClick = useCallback(
    (e) => {
      setOpen(!open);
      sendGAEvent({
        action: "SideMenuItemsClick",
        route: item.route,
        href: item.href,
        title: item.title,
        hasAction: item.action ? "Yes" : "No",
        external: item.type === "external" || item.external ? "Yes" : "No",
      });
      if (item.action) {
        e.preventDefault();
        item.action();
      }
    },
    [item, open]
  );

  return (
    <ul
      className={css(
        styles.navTitleContainer,
        !isOpen && styles.navTitleContainerClosed,
        isHovered && styles.navTitleContainerHover
      )}
      ref={hoverRef}
    >
      <li className={css(styles.closedList)}>
        <button
          data-testid={"itemLink"}
          data-toggle-submenu
          onClick={handleItemClick}
          className={css(styles.navTitle)}
        >
          <span className={css(styles.title)}>{item.title}</span>
          <span className={css(styles.navIcon)}>
            <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} />
          </span>
        </button>

        {open && (
          <ul className={css(styles.submenu, open && styles.addMargin)}>
            {item.subMenu?.map((link, index) => {
              if (
                link.checkPermission &&
                !link.checkPermission(userPermissions)
              ) {
                return null;
              }

              return (
                <SideSubMenuItems
                  className={css(
                    styles.navItem,
                    !isOpen && styles.navItemClosed
                  )}
                  isOpen={isOpen}
                  i={index}
                  item={link}
                >
                  <li>{link.title}</li>
                </SideSubMenuItems>
              );
            })}
          </ul>
        )}
        <div className={css(styles.listSeperater)}></div>
      </li>
    </ul>
  );
};

SideMenuItem.propTypes = {
  hover: PropTypes.bool,
  isOpen: PropTypes.bool,
  item: PropTypes.shape({
    route: PropTypes.string,
    type: PropTypes.string,
    exact: PropTypes.bool,
    action: PropTypes.func,
    external: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
    title: PropTypes.node,
    href: PropTypes.string,
  }).isRequired,
};
SideMenuItem.defaultProps = {
  hover: false,
  isOpen: false,
  item: {},
};

export default SideMenuItem;
