import colours from "styles/colours";

// TODO - Move these to a file so they can be shared with MenuItem.js
export const mainColor = colours.secondary;
export const menuBackgroundColor = "var(--color-secondary-d1)";
export const fontAwesomeFixedWidth = 1.28571429;
export const menuWidth = `${fontAwesomeFixedWidth + 3}rem`; // 3: navItem padding L+R
export const openMenuWidth = 240;
export const logoWidth = 36;
export const logoLeft = 18;
export const logoTop = 24;
export const tabletOpenWidth = "40%";
export const mobileOpenWidth = "80%";
