import { MISSING_IMAGE_URL } from "constants/base";

export default function onImageError(e, image_url = null) {
  e.target.onerror = "";
  e.target.src = image_url || MISSING_IMAGE_URL;

  return true;
}

export const onImageErrorWithFallback =
  (fallback_image_url = null) =>
  (e) =>
    onImageError(e, fallback_image_url);
