import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faArrowAltUp } from "@fortawesome/pro-solid-svg-icons/faArrowAltUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import PodCharts from "./images/podcharts.png";
import APILogo from "./images/podchaser-API-logo-badge.svg";
import PodRover from "./images/Podrover_logo.svg";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import faPodchaser25 from "styles/icons/faPodchaser25";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  contentWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    margin: "1.5rem 0.5rem",
    rowGap: "2.5rem",
    justifyItems: "center",
  },
  items: {
    height: "5.188rem",
    minWidth: "5.625rem",
    background: "#3f0467",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.5rem",
    position: "relative",
    flexDirection: "column",
    cursor: "pointer",
    width: "35vw",
    [ScreenSizes.mdAndAbove]: {
      width: "18vw",
    },
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    marginLeft: "0.3em",
    paddingLeft: "1em",
    marginRight: "1.7rem",
    paddingRight: "2.2rem",
  },
  devAPI: {
    color: "#570d8a",
    fontSize: "5rem",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "0.625rem",
  },
  text: {
    ...gStyles.avalonBold,
    color: "rgb(207, 188, 222)",
    fontSize: "0.875rem",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "0",
    display: "block",
    marginTop: "0.5rem",
  },
  apiLogo: {
    background: `url(${APILogo}) no-repeat`,
    width: "3.688rem",
    height: "1.875rem",
    display: "block",
    backgroundSize: "contain",
    position: "absolute",
  },
  podroverLogo: {
    background: `url(${PodRover}) no-repeat`,
    width: "3.438rem",
    height: "3.125rem",
    backgroundSize: "cover",
  },
  podchartsLogo: {
    background: `url(${PodCharts}) no-repeat`,
    width: "5rem",
    height: "3rem",
    backgroundSize: "cover",
  },
  twentyFive: {
    width: "3.438rem",
    height: "3.125rem",
    position: "absolute",
    display: "block",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  altArrowWrap: {
    alignItems: "flex-end",
    flexDirection: "row",
  },
  altArrow: {
    width: "13.75rem",
    height: "5.375rem",
    color: "#560a89",
    fontSize: "5.375rem",
    textAlign: "center",
    letterSpacing: "0.625rem",
    marginBottom: "-0.625rem",
    marginRight: "-1rem",
    [createBreakpoint({ min: 395, max: 640 })]: {
      marginRight: "-5vw",
    },
  },
  smallAltArrow: {
    fontSize: "2.875rem",
    fontWeight: "solid",
    height: "2.875rem",
    letterSpacing: "0.334rem",
    marginBottom: "-0.75rem",
    marginLeft: "-1rem",
    [createBreakpoint({ min: 395, max: 640 })]: {
      marginLeft: "-5vh",
      marginRight: "unset",
    },
  },
  arrow: {
    transform: "rotate(45deg)",
    width: "0.938rem",
    height: "0.938rem",
    color: "rgb(86, 139, 244)",
    fontSize: "0.875rem",
    fontWeight: "solid",
    textAlign: "center",
    letterSpacing: "0",
    position: "absolute",
    top: 0,
    right: 0,
    marginRight: "0.313rem",
    marginTop: "0.313rem",
  },
};

const SideMenuFooter = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  const handleClick = (title, href) => {
    sendGAEvent({
      action: "SideMenuItemClick",
      href: href,
      title: title,
    });
  };

  return (
    <>
      <div className={css(styles.contentWrapper)}>
        <Link
          onClick={() => handleClick("Developer API", "/profile/settings/api")}
          to="/profile/settings/api"
        >
          <span className={css(styles.items)}>
            <span className={css(styles.devAPI)}>{"</>"}</span>
            <span className={css(styles.apiLogo)}></span>
          </span>
          <span className={css(styles.text)}>Developer API</span>
        </Link>

        <Link
          onClick={() =>
            handleClick("Rising Podcasts", "/articles/podchaser-25")
          }
          to="/articles/podchaser-25"
        >
          <span className={css(styles.items, styles.altArrowWrap)}>
            <FontAwesomeIcon
              className={css(styles.altArrow)}
              icon={faArrowAltUp}
            />
            <FontAwesomeIcon
              className={css(styles.altArrow, styles.smallAltArrow)}
              icon={faArrowAltUp}
            />
            <FontAwesomeIcon
              className={css(styles.twentyFive)}
              icon={faPodchaser25}
            />
          </span>
          <span className={css(styles.text)}>Rising Podcasts</span>
        </Link>

        <a
          href="https://podcharts.co/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            handleClick(
              "podcharts.co",
              "https://podcharts.co/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar"
            )
          }
        >
          <span className={css(styles.items)}>
            <FontAwesomeIcon className={css(styles.arrow)} icon={faArrowUp} />
            <span className={css(styles.podchartsLogo)}></span>
            <span className={css(styles.text)}>podcharts.co</span>
          </span>
        </a>

        <a
          href="https://www.podrover.com/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            handleClick(
              "podrover.com",
              "https://www.podrover.com/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser_navbar"
            )
          }
        >
          <span className={css(styles.items)}>
            <FontAwesomeIcon className={css(styles.arrow)} icon={faArrowUp} />
            <span className={css(styles.podroverLogo)}></span>
            <span className={css(styles.text)}>podrover.com</span>
          </span>
        </a>
      </div>
    </>
  );
};

export default SideMenuFooter;
