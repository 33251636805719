import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";

import GroupShowMore from "./GroupShowMore";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  confirmedGroup: {
    diplay: "flex",
    flexDirection: "column",
    width: "100%",
    borderBottom: "1px #DFE1E6 solid",
    margin: "1rem 0",
  },
  confirmedGroupTargeted: {
    borderLeft: `4px solid ${colours.primary}`,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: "1rem",
  },
  mainInfo: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    ...gStyles.fontSemiBold,
    color: colours.bodyText,
    fontSize: ".93rem",
    margin: "0 1rem",
  },
  subtitle: {
    ...gStyles.fontLight,
    ...gStyles.textEllipsis,
    color: "#ABB0BC",
    fontSize: ".81rem",
  },
  toggle: {
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "1.6rem",
    height: "1.6rem",
    marginLeft: ".5rem",
    marginRight: "1.7rem",
    border: "none",
  },
  toggleArrow: {
    color: "#6E7380",
  },
};

const ConfirmedGroup = (props) => {
  const {
    open,
    children,
    subtitle,
    confirmed,
    isTargeted,
    onToggle,
    toggleDataId,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <div
      data-id="confirmed-group"
      className={css(
        styles.confirmedGroup,
        isTargeted && styles.confirmedGroupTargeted
      )}
    >
      <div className={css(styles.headerContainer)}>
        <div className={css(styles.mainInfo)}>
          <div className={css(styles.title)}>
            {confirmed ? "Confirmed" : "Not Confirmed"}
          </div>
          <div className={css(styles.subtitle)}>{subtitle}</div>
        </div>
        <button
          className={css(styles.toggle)}
          onClick={onToggle}
          data-id={toggleDataId}
        >
          <span className={css(styles.toggleArrow)}>
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </span>
        </button>
      </div>
      <GroupShowMore open={open}>{children}</GroupShowMore>
    </div>
  );
};

ConfirmedGroup.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  toggleDataId: PropTypes.string,
  children: PropTypes.node,
  subtitle: PropTypes.node,
  confirmed: PropTypes.bool,
  isTargeted: PropTypes.bool,
};

ConfirmedGroup.defaultProps = {
  children: null,
  open: false,
  subtitle: null,
  confirmed: false,
  isTargeted: false,
};

export default ConfirmedGroup;
