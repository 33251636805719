import { css } from "aphrodite";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { compose } from "redux";

import LoadingOverlay from "components/Common/LoadingOverlay";
import Tabs from "components/Common/Tabs";
import creditsWrapper, {
  getId,
} from "components/EntityWrappers/creditsWrapper";
import CreatorViewAppearancesStructuredData from "pages/CreatorView/Appearances/CreatorViewAppearancesStructuredData";
import GroupedAppearanceList from "pages/CreatorView/Appearances/GroupedAppearanceList";

import creatorActions from "actions/creator";
import { selectList, selectListAdditional } from "selectors/pagination";
import { selectSpecificPodcast } from "selectors/podcast";
import { truncateString } from "utils/truncate";
import { getCreatorEditSelectPodcastUrl } from "utils/url/creatorUrls";

import useActionCreators from "hooks/useActionCreators";
import useIsModerator from "hooks/useIsModerator";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  appearanceListMobile: {
    marginTop: "1rem",
  },
  appearanceList: {
    display: "flex",
    flexDirection: "column",
  },

  tabMobile: {
    maxWidth: "100%",
    width: "100%",
  },
  noCreditsMessage: {
    ...gStyles.fontSemiBold,
    color: colours.bodyText,
    padding: "4rem 1rem 2rem",
    fontSize: "1.2rem",
    textAlign: "center",
    opacity: "0.6",
    lineHeight: "1.8rem",
  },
  noCreditsMessageMobile: {
    padding: "1rem",
    maxWidth: "27.5rem",
    margin: "auto",
  },
  noCreditsMessageLink: {
    color: colours.primary,
  },
  contributePrompt: {
    ...gStyles.fontMediumItalic,
    marginRight: ".5rem",
    fontSize: " .8em",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    textAlign: "right",
  },
  contributeLink: {
    color: colours.primary,
    ...gStyles.fontSemiBold,
  },
};

const tabsStyles = {
  tabs: {
    background: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottom: "none",
    marginBottom: 0,
    overflowY: "hidden",
    overflowX: "auto",
    padding: "1rem 0 0 1rem",
    boxShadow: "0 5px 16px 0 rgba(0,0,0,0.1)",
  },
  tabContainer: {
    position: "relative",
    top: 3,
    padding: "0 1.2rem 0 0",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 2.5rem 0 0",
    },
  },
  tabContent: {
    padding: "0 0.75rem",
  },
  tab: {
    ...gStyles.fontLight,
    fontSize: "1rem",
    paddingBottom: 10,
    borderBottomWidth: 3,
    marginBottom: 3,
    whiteSpace: "nowrap",
  },
  currentTab: {
    ...gStyles.fontBold,
  },
};

const loadingStyles = {
  noOverlay: {
    fontSize: "2rem",
    minHeight: "10rem",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    maxWidth: "2em",
  },
};

const GROUP_OPTIONS = [
  { value: "confirmed", label: "Confirmed", moderating: true },
  { value: "podcast_id", label: "Podcast" },
  { value: "role", label: "Role" },
];

const CreatorViewAppearancesEpisodesView = (props) => {
  const { credits, creator, mobile, loading, loaded } = props;
  const { styles } = useStyles(baseStyles, props);

  const location = useLocation();
  const { hash } = location;

  const key = getId(props);

  const groups = useReduxState(
    (state) => {
      const listDetails = selectList(state, { key });

      return listDetails && listDetails.get("groups");
    },
    [key, credits]
  );

  const podcasts = useReduxState(
    (state) => {
      let pods = Map({});

      // need the podcast details for the tabs if the first group is podcast
      if (credits && groups && groups.first().get("field") === "podcast_id") {
        pods = credits
          .getIn([0, "by_podcast_id"])
          .reduce(
            (agg, next) =>
              agg.set(
                next.get("podcast_id"),
                selectSpecificPodcast(state, next.get("podcast_id"))
              ),
            Map()
          );
      }

      return pods;
    },
    [groups, credits]
  );

  const episodeCount = useReduxState(
    (state) => selectListAdditional(state, { key }).get("episodeCount") || 0,
    [key]
  );

  const renderTabHeading = useCallback(
    (creditGroup, field) => {
      switch (field) {
        case "role": {
          return creditGroup.getIn(["role", "title"]);
        }
        case "podcast_id": {
          const podcast = podcasts.get(creditGroup.get("podcast_id"));

          return podcast && truncateString(podcast.get("title"), 25);
        }
        case "confirmed":
          return creditGroup.get("confirmed") ? "Confirmed" : "Not Confirmed";
        default:
          return "";
      }
    },
    [podcasts]
  );

  const topLevelTabs = useMemo(() => {
    // use the top level of grouping as the tabs
    const firstGroup = groups.first();
    const firstCreditGroup = credits.first();

    if (firstCreditGroup) {
      const field = firstGroup.get("field");
      const immutableTabs = firstCreditGroup
        .get(`by_${field}`)
        .map((creditGroup) => ({
          key: creditGroup.getIn(["role", "code"]),
          field,
          title: renderTabHeading(creditGroup, field),
          creditGroup: Map({ [`by_${field}`]: List([creditGroup]) }),
        }));

      if (mobile && immutableTabs.size > 1) {
        return immutableTabs.unshift({
          key: "all_group",
          field: "all",
          title: "All",
          creditGroup: Map({
            [`by_${field}`]: firstCreditGroup.get(`by_${field}`),
          }),
        });
      }

      return immutableTabs;
    }

    return List([]);
  }, [groups, credits, renderTabHeading, mobile]);

  const renderNoCreditsMessage = () => {
    const informalName = creator.get("informal_name");

    return (
      <div
        className={css(
          styles.noCreditsMessage,
          mobile && styles.noCreditsMessageMobile
        )}
      >
        {`We don't have any credits for ${informalName} yet. Our Creator database is growing daily, so rest assured we'll add them shortly!`}
      </div>
    );
  };

  const renderGroupList = (creditGroup = null) => {
    if (episodeCount === 0) {
      return renderNoCreditsMessage();
    }

    return (
      <GroupedAppearanceList
        groups={groups}
        creditGroup={creditGroup || credits.first()}
        creator={creator}
        options={GROUP_OPTIONS}
      />
    );
  };

  const renderTab = (passedParams) => {
    if (!passedParams.creditGroup) {
      // empty tab?
      return null;
    }

    return (
      <div className={css(styles.tabMobile)}>
        <div className={css(styles.appearanceListMobile)}>
          {renderGroupList(passedParams.creditGroup)}
        </div>
        {renderContributePrompt()}
      </div>
    );
  };

  const renderMobile = () => (
    <Fragment>
      {episodeCount === 0 ? (
        renderNoCreditsMessage()
      ) : (
        <Tabs
          tabs={topLevelTabs}
          styles={tabsStyles}
          renderContent={renderTab}
          intialTabKey={hash ? hash.replace("#", "") : null}
        />
      )}
    </Fragment>
  );

  const renderDesktop = () => (
    <Fragment>
      <div className={css(styles.appearanceList)}>
        {/* TODO: check if this isn't grouped at all, at which point just show an episode list */}
        {renderGroupList()}
      </div>
      {renderContributePrompt()}
    </Fragment>
  );

  const renderContributePrompt = () => (
    <div className={css(styles.contributePrompt)}>
      <p>{`Are we missing a credit for ${creator.get("informal_name")}?`}</p>
      <p>
        <Link
          className={css(styles.contributeLink)}
          to={getCreatorEditSelectPodcastUrl(creator)}
        >
          Contribute to our database!
        </Link>
      </p>
    </div>
  );

  const renderContent = () => {
    if (!loaded || loading) {
      return (
        <LoadingOverlay
          noOverlay
          noPadding
          styles={loadingStyles}
          key="creatorViewCreditsLoadingOverlay"
        />
      );
    }

    return mobile ? renderMobile() : renderDesktop();
  };

  return (
    <Fragment>
      <CreatorViewAppearancesStructuredData creator={creator} />
      {renderContent()}
    </Fragment>
  );
};

CreatorViewAppearancesEpisodesView.propTypes = {
  creator: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
  credits: PropTypes.object,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
};

CreatorViewAppearancesEpisodesView.defaultProps = {
  mobile: false,
  credits: List(),
  creator: Map({}),
  loading: false,
  loaded: false,
};

const CreatorViewAppearancesEpisodesViewWithLoader = compose(
  creditsWrapper({
    groups: [
      { field: "role", size: 9999 },
      { field: "podcast_id", size: 9999 },
    ],
    pageSize: 10000,
    loadingStyles,
  })
)(CreatorViewAppearancesEpisodesView);

const CreatorViewAppearancesEpisodesViewRedux = (props) => {
  const { isModerator } = useIsModerator();

  const { loadCreatorRoles } = useActionCreators({
    loadCreatorRoles: creatorActions.loadCreatorRoles,
  });

  useEffect(() => {
    loadCreatorRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CreatorViewAppearancesEpisodesViewWithLoader
      {...props}
      isModerating={isModerator}
    />
  );
};

export default CreatorViewAppearancesEpisodesViewRedux;
