import { css } from "aphrodite";
import { List, Map } from "immutable";
import PropTypes from "prop-types";

import AppearanceEpisodes from "pages/CreatorView/Appearances/AppearanceEpisodes";
import AppearanceGroup from "pages/CreatorView/Appearances/AppearanceGroup";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  episodeList: {
    display: "flex",
    flexDirection: "column",
  },
  moreLink: {
    ...gStyles.fontSemiBold,
    maxWidth: "10.5rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "0.69rem",
    padding: "0.5rem",
    color: "#ABB0BC",
    boxShadow: "0 5px 16px 0 rgba(0,0,0,0.07)",
    cursor: "pointer",
  },
};

const GroupedAppearanceList = (props) => {
  const {
    groups: passedGroups,
    creditGroup: passedCreditGroup,
    options: passedOptions,
    creator,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  // These intervals are needed because the eitehr the lazy
  // loading or just the loading time of large lists prevents
  // the ID from existing by the time we want to scroll to it.
  // TODO: Why does this get called a bunch of times (parent rerender)
  // when switching tabs or typing in search autocomplete at top of
  // page? - Figured it out, it's caused by the hash change hack
  // making the router think the page is changing, so it refreshes
  // the component which causes useScrollToHash to fire again

  const renderGroup = (
    options = [],
    groups,
    creditGroup,
    depth = 0,
    groupPath = List(),
    parentGroup = null,
    parentField = null,
    parentIndex = null
  ) => {
    if (groups.size === 0) {
      return (
        <AppearanceEpisodes
          key="groupedEpisodes"
          creditGroup={creditGroup}
          groups={passedGroups}
          groupPath={groupPath}
          creator={creator}
        />
      );
    }

    const thisGroup = groups.first();
    const childGroups = creditGroup.get(`by_${thisGroup.get("field")}`);

    return childGroups.map((childGroup, index) => {
      let pushGroup = childGroup.delete("count");
      let nextField = null;
      let nextOption = null;
      let nextGroup = null;

      if (groups.get(1)) {
        nextField = groups.get(1).get("field");
        nextOption = options.find((op) => op.value === nextField);
        nextGroup = childGroup.get(`by_${nextField}`);
        pushGroup = pushGroup.delete(`by_${nextField}`);
      }

      const thisGroupPath = groupPath.push(pushGroup);
      const key = `${thisGroup.get("field")}-${depth}=${index}`;

      return (
        <AppearanceGroup
          key={key}
          field={thisGroup.get("field")}
          creditGroup={childGroup}
          depth={depth}
          count={childGroup.get("count")}
          groupPath={thisGroupPath}
          groups={passedGroups}
          parentGroup={parentGroup}
          parentField={parentField}
          nextField={nextField}
          nextOption={nextOption}
          nextGroup={nextGroup}
          isLast={index + 1 === childGroups.size}
        >
          {renderGroup(
            options,
            groups.shift(),
            childGroup,
            depth + 1,
            thisGroupPath,
            childGroup,
            thisGroup.get("field"),
            index
          )}
        </AppearanceGroup>
      );
    });
  };

  return (
    <div className={css(styles.wrapper)}>
      {renderGroup(passedOptions, passedGroups, passedCreditGroup)}
    </div>
  );
};

GroupedAppearanceList.propTypes = {
  creditGroup: PropTypes.instanceOf(Map).isRequired,
  creator: PropTypes.instanceOf(Map).isRequired,
  options: PropTypes.array.isRequired,
  groups: PropTypes.instanceOf(List),
};

GroupedAppearanceList.defaultProps = {
  groups: List(),
  approveCredits: false,
};

export default GroupedAppearanceList;
