import PropTypes from "prop-types";
import { memo, useContext, useMemo } from "react";

import IntersectionImage from "components/Images/IntersectionImage";
import RequestContext from "pages/RequestContext";

import { getSrcUrls } from "utils/images";

function LazyImage(props) {
  const {
    background,
    src,
    height,
    width,
    offset,
    alt,
    className,
    title,
    lazy,
    cached,
    imageStyles,
    imgProps: passedImgProps,
    ...rest
  } = props;

  const imgProps = {
    ...passedImgProps,
    className,
    title,
    alt,
    ...rest,
  };

  const { isCrawler } = useContext(RequestContext);

  const srcUrls = useMemo(
    () =>
      getSrcUrls(src, width, height || width, {
        cached,
      }),
    [cached, width, height, src]
  );

  const imgStyle = useMemo(
    () => ({
      ...props.style,
      width: "100%",
      height: "100%",
    }),
    [props.style]
  );

  const Component = background ? "span" : "img";

  if (!background) {
    imgProps.srcSet = `${srcUrls.regular} 1x, ${
      srcUrls.retina || srcUrls.regular
    } 2x`;
    imgProps.src = srcUrls.regular;
  }

  if (lazy) {
    if (isCrawler) {
      // just return the image without any lazy loading to crawlers so we don't rely on JS
      return (
        <Component
          {...imgProps}
          style={{
            height: "100%",
            width: "100%",
            display: "block",
            backgroundImage: background ? `url(${srcUrls.regular})` : null,
            backgroundSize: background ? "cover" : null,
          }}
        />
      );
    }

    return (
      <IntersectionImage
        offset={offset}
        placeholder={srcUrls.pre}
        src={srcUrls.regular}
        style={imgStyle}
        background={background}
        animate
        imageStyles={imageStyles}
        {...imgProps}
      />
    );
  }
}

LazyImage.propTypes = {
  cached: PropTypes.bool,
  src: PropTypes.string.isRequired,
  // width is required so we can make sure to always give it a sensible image size to cache at
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  background: PropTypes.bool,
  offset: PropTypes.number,
  alt: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  lazy: PropTypes.bool,
};

LazyImage.defaultProps = {
  cached: true,
  height: undefined,
  background: false,
  offset: 400,
  alt: "",
  className: undefined,
  title: undefined,
  lazy: true,
};

export default memo(LazyImage);
