import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";

import ProGraphlines from "./images/Graphics-Pro-Sidebar-Ad.png";
import ProGraphlines2x from "./images/Graphics-Pro-Sidebar-Ad2x.png";
import ProLogo from "./images/proLogo.png";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  outerWrapper: {
    padding: "0 25px",
  },
  contentWrapper: {
    ...gStyles.avalonBold,
    height: "4.875rem",
    maxWidth: "29rem",
    background: `url(${ProGraphlines}) no-repeat`,
    backgroundSize: "100% 100%",
    backgroundPosition: "right center",
    fontSize: "1.063rem",
    borderRadius: "0.625rem",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "0.625rem 0",
    margin: "auto auto",
    marginTop: "1.5rem",
    border: "none",
    position: "relative",
    cursor: "pointer",
    // marginLeft: "2rem",
    [ScreenSizes.retinaOnly]: {
      backgroundImage: `url(${ProGraphlines2x})`,
    },
  },
  unlockIconWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    padding: "0 1rem",
    gap: "0.5rem",
  },
  button: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
  },
  textLogo: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.813rem",
    gap: "0.875rem",
    justifyContent: "flex-end",
    marginRight: "2rem",
    position: "absolute",
    right: "0",
    bottom: "0.6rem",
  },
  proLogo: {
    background: `url(${ProLogo}) no-repeat center right`,
    height: "1.75rem",
    width: "3.675rem",
    backgroundSize: "contain",
  },
  seperater: {
    height: "0.063rem",
    background: "rgb(8, 0, 43)",
    borderRadius: "0",
    margin: "1.5rem 1.5rem",
    marginTop: "2rem",
  },
};

const SideMenuUnlockPro = (props) => {
  const { styles, css } = useStyles(baseStyles, props);
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });
  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      context: "ProLeadForm",
    });
  }, []);

  const renderProLeadForm = useCallback(() => {
    sendGAEvent({
      action: "RenderProleadModalFormFromMobileSideNav",
      context: "ProLeadForm",
    });

    showModal("proLeadForm", {
      onDemoRequestSubmitted,
    });
  }, [showModal, onDemoRequestSubmitted]);

  return (
    <>
      <div className={css(styles.outerWrapper)}>
        <div onClick={renderProLeadForm} className={css(styles.contentWrapper)}>
          <div className={css(styles.unlockIconWrapper)}>
            <span>Unlock Insights & Data</span>
            <span className={css(styles.button)}>
              <FontAwesomeIcon icon={faCaretRight} />
            </span>
          </div>
          <div className={css(styles.textLogo)}>
            <span>Only with</span>
            <div className={css(styles.proLogo)} />
          </div>
        </div>
      </div>
      <div className={css(styles.seperater)} />
    </>
  );
};

export default SideMenuUnlockPro;
