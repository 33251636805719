import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { episodeCreditSchema } from "api/schema/creator_podcast";
import { List, Map } from "immutable";
import { denormalize } from "normalizr";
import PropTypes from "prop-types";
import { Fragment, useCallback } from "react";

import CreatorViewAppearanceEpisode from "pages/CreatorView/Appearances/AppearanceEpisode";

import paginationActions from "actions/pagination";
import {
  selectListHasMore,
  selectListIds,
  selectListLoading,
  selectListTotal,
} from "selectors/pagination";
import { getCreditEpisodesKey } from "utils/entity/creator";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  episodeList: {
    display: "flex",
    flexDirection: "column",
  },
  moreLink: {
    ...gStyles.fontBold,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: ".625rem",
    textTransform: "uppercase",
    padding: ".875rem",
    color: colours.primary,
    cursor: "pointer",
  },
  moreLinkSpinner: {
    fontSize: "1rem",
    marginLeft: ".5rem",
  },
};

const AppearanceEpisodes = (props) => {
  const { creator, groupPath, groups } = props;
  const { styles } = useStyles(baseStyles, props);

  const {
    listKey,
    ids,
    hasMore,
    remainingCount,
    loading,
    hasRoleGroup,
    hasPodcastGroup,
  } = useReduxState(
    (state) => {
      const listKey = getCreditEpisodesKey(
        creator.get("id"),
        groupPath,
        groups
      );
      const ids = selectListIds(state, listKey);
      const total = selectListTotal(state, { key: listKey });

      return {
        listKey,
        ids,
        hasMore: selectListHasMore(state, { key: listKey }),
        remainingCount: total - ids.size,
        loading: selectListLoading(state, { key: listKey }),
        hasRoleGroup: !!groups.find((g) => g.get("field") === "role"),
        hasPodcastGroup: !!groups.find((g) => g.get("field") === "podcast_id"),
      };
    },
    [creator, groups, groupPath]
  );

  const credits = useReduxState(
    (state) =>
      denormalize(
        ids,
        [episodeCreditSchema],
        Map({ credits: state.creator.getIn(["credits", "entities"]) })
      ),
    [ids]
  );

  const { nextPage } = useActionCreators({
    nextPage: paginationActions.nextPage,
  });

  const handleLoadMoreEpisodes = useCallback(
    () => nextPage(listKey),
    [listKey, nextPage]
  );

  const renderMoreButton = () => (
    <span className={css(styles.moreLink)}>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <span
          data-id="appearance-episodes-more"
          onClick={handleLoadMoreEpisodes}
          onKeyDown={(e) => eventIsFieldTrigger(e) && handleLoadMoreEpisodes(e)}
          role="button"
          tabIndex={0}
        >
          {`View ${remainingCount} more`}
        </span>
      )}
      {loading && (
        <span className={css(styles.moreLinkSpinner)}>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      )}
    </span>
  );

  return (
    <Fragment>
      <div data-id="appearance-episodes" className={css(styles.episodeList)}>
        {credits
          .filter((c) => c.get("entity_type") === "episode")
          .map((hit, index) => (
            <CreatorViewAppearanceEpisode
              episodeId={hit.get("entity_id")}
              key={hit.get("entity_id")}
              credit={hit}
              showRole={!hasRoleGroup}
              showPodcast={!hasPodcastGroup}
              showBorder={credits.size - 1 !== index || hasMore}
            />
          ))}
      </div>
      {hasMore && renderMoreButton()}
    </Fragment>
  );
};

AppearanceEpisodes.propTypes = {
  creator: PropTypes.instanceOf(Map).isRequired,
  groupPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  groups: PropTypes.instanceOf(List),
};

AppearanceEpisodes.defaultProps = {
  groupPath: null,
  groups: null,
};

export default AppearanceEpisodes;
