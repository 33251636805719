export default function intersperse(
  arr,
  arrFunc,
  options = { surround: true }
) {
  if (!arr.length) return [];
  if (arr.length === 1 && !options.surround) return arr.slice(0);

  const items = options.surround ? [arrFunc(null, arr[0]), arr[0]] : [arr[0]];
  for (let i = 1, len = arr.length; i < len; ++i) {
    const newItem = arrFunc(arr[i - 1], arr[i], i);
    items.push(newItem, arr[i]);
  }
  if (options.surround) {
    items.push(arrFunc(arr[arr.length - 1], null, "last"));
  }

  return items;
}
