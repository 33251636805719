import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const CreditSubmissionListContainer = Loadable(() =>
  import("./CreditSubmissionListContainer")
);

function CreditSubmissionListAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const CreditSubmissionListContainerContainer = (props) => {
  return (
    <CreditSubmissionListContainer
      {...props}
      fallback={<CreditSubmissionListAsyncLoading />}
    />
  );
};

export default CreditSubmissionListContainerContainer;
