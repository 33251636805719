import PropTypes from "prop-types";
import { memo, Fragment, useMemo } from "react";

import TabTitle from "components/Entities/Page/TabTitleAsync";
import Title from "components/Entities/Page/TitleAsync";
import CreditSubmissionListContainer from "components/Lists/CreditSubmission/async";

import useList from "hooks/useList";
import useWindowSize from "hooks/useWindowSize";

const CreditSubmissionStub = (props) => {
  const {
    listKey,
    staticFilters: passedStaticFilters,
    title,
    viewAllLink,
    showAllSubmissions,
    titleStyles,
    tabTitle,
  } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const staticFilters = useMemo(
    () => ({
      ...passedStaticFilters,
      ...(showAllSubmissions
        ? {}
        : { submitted_by: { value: "logged_in_user" } }),
      state: { value: "submitted" },
    }),
    [passedStaticFilters, showAllSubmissions]
  );

  const { total, loaded } = useList(listKey);

  const renderTitle = () => {
    const titleProps = {
      listKey,
      title,
      count: mobile ? total : null,
      TitleComponent: "h2",
      link: viewAllLink,
      // viewAllTitle={viewAllTitle || (entity.get("title") && `Credit Submissions for ${entity.get("title")}`) || "Credit Submissions"}
      styles: titleStyles,
    };

    if (tabTitle) {
      return <TabTitle {...titleProps} />;
    }

    return <Title {...titleProps} />;
  };

  return (
    <Fragment>
      {loaded && total > 0 && title && renderTitle()}
      <CreditSubmissionListContainer
        {...props}
        listKey={listKey}
        staticFilters={staticFilters}
        pageSize={5}
        hideOnNoResults
        hideFilters={!showAllSubmissions}
      />
    </Fragment>
  );
};

CreditSubmissionStub.propTypes = {
  title: PropTypes.string,
  listKey: PropTypes.string.isRequired,
  staticFilters: PropTypes.object,
  viewAllLink: PropTypes.string,
  showAllSubmissions: PropTypes.bool,
  titleStyles: PropTypes.object,
  tabTitle: PropTypes.bool,
};
CreditSubmissionStub.defaultProps = {
  title: null,
  staticFilters: {},
  viewAllLink: null,
  showAllSubmissions: false,
  titleStyles: null,
  tabTitle: false,
};

export default memo(CreditSubmissionStub);
