import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const TabTitle = Loadable(() => import("./TabTitle"));

function TabTitleAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const TabTitleContainer = (props) => {
  return <TabTitle {...props} fallback={<TabTitleAsyncLoading />} />;
};

export default TabTitleContainer;
