import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";

import GroupShowMore from "./GroupShowMore";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  roleGroup: {
    diplay: "flex",
    flexDirection: "column",
    width: "100%",
    borderBottom: "1px #DFE1E6 solid",
  },
  roleGroupTargeted: {
    borderLeft: `4px solid ${colours.primary}`,
  },
  lastRoleGroup: {
    borderBottom: "1px transparent solid",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: "1.5rem 0",
    marginRight: "1rem",
  },
  image: {
    width: "2.625rem",
    minWidth: "2.625rem",
    height: "2.625rem",
    backgroundSize: "contain",
    backgroundPosition: "center",
    marginRight: ".5rem",
  },
  info: {
    ...gStyles.textEllipsis,
    display: "flex",
    flexDirection: "column",
  },
  infoMainRow: {
    ...gStyles.textEllipsis,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    ...gStyles.fontBold,
    color: "#000",
    margin: 0,
    padding: 0,
    fontSize: "1rem",
    marginRight: ".75rem",
  },
  subtitle: {
    ...gStyles.fontRegular,
    ...gStyles.textEllipsis,
    color: colours.bodyText,
    display: "flex",
    fontSize: ".875rem",
  },
  toggle: {
    fontSize: ".875rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "1.25rem",
    height: "1.25rem",
    cursor: "pointer",
    border: "none",
    border: "1px solid rgba(42,58,100,0.15)",
    borderRadius: "50%",
    marginLeft: "auto",

    [ScreenSizes.lgAndAbove]: {
      marginLeft: 10,
    },
  },
  toggleArrow: {
    color: "#6E7380",
  },
};

const RoleGroup = (props) => {
  const {
    children,
    description,
    isLast,
    isTargeted,
    onToggle,
    open,
    subtitle,
    title,
    toggleDataId,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <div
      data-id="role-group"
      className={css(
        styles.roleGroup,
        isLast && styles.lastRoleGroup,
        isTargeted && styles.roleGroupTargeted
      )}
    >
      <div className={css(styles.headerContainer)} title={description}>
        <div className={css(styles.info)}>
          <div className={css(styles.infoMainRow)}>
            <h2 className={css(styles.title)}>{title}</h2>
            <div className={css(styles.subtitle)}>{subtitle}</div>
          </div>
        </div>
        <button
          className={css(styles.toggle)}
          onClick={onToggle}
          data-id={toggleDataId}
        >
          <span className={css(styles.toggleArrow)}>
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </span>
        </button>
      </div>
      <GroupShowMore open={open}>{children}</GroupShowMore>
    </div>
  );
};

RoleGroup.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  isLast: PropTypes.bool,
  isTargeted: PropTypes.bool,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.string,
  toggleDataId: PropTypes.string,
};

RoleGroup.defaultProps = {
  children: null,
  description: null,
  isLast: false,
  isTargeted: false,
  open: false,
  subtitle: null,
  title: null,
};

export default RoleGroup;
