import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";

import EntityLink from "components/Entities/EntityLink";
import LazyImage from "components/Images/LazyImage";
import AuthorityBadge from "components/Podcast/AuthorityBadgeAsync";

import GroupShowMore from "./GroupShowMore";

import onImageError from "utils/entity/onImageError";
import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  podcastGroup: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#FFF",
    borderRadius: 6,
    boxShadow: "0 5px 16px 0 rgba(0,0,0,0.07)",
    marginBottom: ".75rem",
    transition: generateTransition({ target: "border-left-width" }),
  },
  podcastGroupTargeted: {
    borderLeft: `4px solid ${colours.primary}`,
  },
  lastPodcastGroup: {
    marginBottom: "2rem",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: ".625rem",
  },
  textContainer: {
    ...gStyles.textEllipsis,
    height: "2.625rem",
    flex: "0 1 auto",
    marginRight: "auto",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  title: {
    fontSize: "1rem",
    margin: 0,
    padding: 0,
  },
  titleLink: {
    ...gStyles.avalonBold,
    ...gStyles.textEllipsis,
    display: "block",
    color: colours.bodyText,
    marginBottom: ".25rem",
  },
  info: {
    ...gStyles.fontRegular,
    ...gStyles.textEllipsis,
    color: colours.bodyText,
    fontSize: ".85rem",
    display: "flex",
    alignItems: "center",
  },
  toggle: {
    fontSize: "1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2rem",
    cursor: "pointer",
    border: "none",
  },
  toggleArrow: {
    color: colours.bodyText,
  },
  children: {
    borderTop: "1px #DFE1E6 solid",
    width: "100%",
  },
};

const imageStyles = {
  imageFallback: {
    width: "2.625rem",
    minWidth: "2.625rem",
    height: "2.625rem",
    backgroundSize: "contain",
    backgroundPosition: "center",
    marginRight: ".813rem",
  },
};

const authorityBadgeStyles = {
  noTooltip: {
    marginTop: "-0.3rem",
  },
  tooltip: {
    marginTop: "-0.3rem",
  },
};

const claimedBadgeStyles = {
  styles: authorityBadgeStyles,
  size: 13,
};

const lazyImageStyles = {
  width: 42,
  height: 42,
};

const PodcastGroup = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const {
    podcast,
    open,
    children,
    subtitle,
    isLast,
    isTargeted,
    onToggle,
    toggleDataId,
  } = props;

  if (!podcast) {
    return null;
  }

  return (
    <div
      data-id="role-group"
      className={css(
        styles.podcastGroup,
        isLast && styles.lastPodcastGroup,
        isTargeted && styles.podcastGroupTargeted
      )}
    >
      <div className={css(styles.headerContainer)}>
        <LazyImage
          src={podcast.get("image_url")}
          onError={onImageError}
          title={podcast.get("title")}
          alt={podcast.get("title")}
          width={42}
          height={42}
          imageStyles={imageStyles}
          imgProps={lazyImageStyles}
        />
        <div className={css(styles.textContainer)}>
          <div className={css(styles.infoWrapper)}>
            <h2 className={css(styles.title)}>
              <div className={css(styles.titleLink)}>
                <EntityLink
                  entity={podcast}
                  entity_type="podcast"
                  claimedBadgeProps={claimedBadgeStyles}
                  showClaimedBadge
                  avalon
                />
                <AuthorityBadge
                  entity={podcast}
                  entity_type="podcast"
                  styles={authorityBadgeStyles}
                  size={13}
                  showTooltip
                />
              </div>
            </h2>
            <div className={css(styles.info)}>{subtitle}</div>
          </div>
        </div>
        <button
          className={css(styles.toggle)}
          onClick={onToggle}
          data-id={toggleDataId}
        >
          <span className={css(styles.toggleArrow)}>
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </span>
        </button>
      </div>
      {children && (
        <GroupShowMore open={open}>
          <div className={css(styles.children)}>{children}</div>
        </GroupShowMore>
      )}
    </div>
  );
};

PodcastGroup.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  toggleDataId: PropTypes.string,
  children: PropTypes.node,
  subtitle: PropTypes.node,
  isLast: PropTypes.bool,
  isTargeted: PropTypes.bool,
};

PodcastGroup.defaultProps = {
  children: null,
  open: false,
  subtitle: null,
  isLast: false,
  isTargeted: false,
};

export default PodcastGroup;
