import { css } from "aphrodite";
import { format } from "date-fns/format";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { Link } from "react-router-dom";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import CardActions from "components/Entities/Cards/CardActions";
import LazyImage from "components/Images/LazyImage";
import EpisodeTypeBadge from "components/Podcast/EpisodeTypeBadgeContainer";

import { SHORT_DATE_ONLY_FORMAT } from "constants/date";
import {
  EPISODE_TYPE_BADGE_HEIGHT,
  DESKTOP_ACTIONS_HEIGHT,
} from "constants/podcast";
import { selectRoles } from "selectors/creator";
import {
  selectSpecificEpisode,
  selectSpecificPodcast,
} from "selectors/podcast";
import getEntityImageUrl from "utils/entity/getEntityImageUrl";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import onImageError from "utils/entity/onImageError";
import { VOICE_ACTOR_CODE } from "utils/roles";
import intersperse from "utils/text/intersperse";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  creatorViewAppearanceEpisode: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: ".625rem",
    height: 46,
  },
  withBorder: {
    borderBottom: "1px #DFE1E6 solid",
  },
  imageContainer: {
    width: "2.625rem",
    minWidth: "2.625rem",
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-start",
    justifyContent: "flex-end",
    marginRight: ".813rem",
  },
  info: {
    ...gStyles.textEllipsis,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    marginRight: "1rem",
    lineHeight: 1,
    paddingTop: 2,
  },
  cardActions: {
    display: "flex",
    flexDirection: "row",
  },
  mainRow: {
    ...gStyles.fontSemiBold,
    ...gStyles.textEllipsis,
    color: colours.bodyText,
    display: "flex",
    flexDirection: "row",
    fontSize: ".875rem",
  },
  episodeName: {
    ...gStyles.avalonSemiBold,
    ...gStyles.textEllipsis,
    color: colours.bodyText,
    marginRight: ".5rem",
    display: "inline-flex",
    alignItems: "center",
  },
  airDate: {
    ...gStyles.fontRegular,
    color: "#000",
    fontSize: "0.9em",
    paddingTop: "0.1em",
    display: "inline-flex",
    alignItems: "center",
  },
  smallRow: {
    ...gStyles.fontRegular,
    ...gStyles.textEllipsis,
    fontSize: "0.75rem",
    color: "#333",
    marginTop: "0.375rem",
  },
  characterSeparator: {
    margin: "0 0.25em",
  },
};

const imageStyles = {
  imageFallback: {
    minWidth: "1.625rem",
    width: "1.625rem",
    height: "1.625rem",
  },
};

const episodeTypeBadgeStyles = {
  outer: {
    marginRight: "0.5em",
  },
};

const IMG_PROPS = {
  width: 26,
  height: 26,
};

const CreatorViewAppearanceEpisode = (props) => {
  const { showBorder, credit, showRole, noActions, hideImage, episodeId } =
    props;

  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess, isWindowSize } = useWindowSize();
  const isNarrow = isWindowSizeOrLess("small") || isWindowSize("large");
  const isTiny = isWindowSizeOrLess("tiny");

  const episode = useReduxState(
    (state) => selectSpecificEpisode(state, episodeId),
    [episodeId]
  );

  const podcast = useReduxState(
    (state) =>
      episode && selectSpecificPodcast(state, episode.get("podcast_id")),
    [episodeId]
  );

  const roles = useReduxState((state) => selectRoles(state), [episodeId]);

  const actions = useMemo(
    () =>
      [
        {
          type: "ellipsis",
          showEllipsisItems: [
            "view",
            "mark",
            "list",
            "share",
            "external",
            "credits",
            (isNarrow || noActions) && "bookmark",
            (isNarrow || noActions) && "rate",
            isTiny && "play",
          ],
        },
        !isNarrow && !noActions && { type: "bookmark" },
        !isNarrow && !noActions && { type: "rate" },
        !isTiny && {
          type: "play",
          asButton: true,
          noLabel: true,
        },
      ].filter((action) => !!action),
    [isNarrow, isTiny, noActions]
  );

  const renderImage = () => {
    if (!episode) {
      return null;
    }

    return (
      <LazyImage
        className={css(styles.imageContainer)}
        src={getEntityImageUrl(episode.set("podcast", podcast), "episode")}
        onError={onImageError}
        alt={episode.get("title")}
        title={episode.get("title")}
        width={30}
        imageStyles={imageStyles}
        imgProps={IMG_PROPS}
      />
    );
  };

  const renderInfo = () => {
    if (!episode || !podcast) {
      return (
        <div className={css(styles.episodeName)}>Episode details not found</div>
      );
    }

    const isVoiceActor =
      credit.get("role") === VOICE_ACTOR_CODE ||
      credit.get("role") === "voice_talent";

    return (
      <div className={css(styles.info)}>
        <div className={css(styles.mainRow)}>
          <LazyLoadComponent height={EPISODE_TYPE_BADGE_HEIGHT}>
            <EpisodeTypeBadge
              episode={episode}
              styles={episodeTypeBadgeStyles}
            />
          </LazyLoadComponent>
          <Link
            className={css(styles.episodeName)}
            to={getEpisodeUrl(episode, { podcast })}
            target="_blank"
          >
            {episode.get("title")}
          </Link>
          <LazyLoadComponent height={16}>
            <span className={css(styles.airDate)}>
              {format(episode.get("air_date"), SHORT_DATE_ONLY_FORMAT)}
            </span>
          </LazyLoadComponent>
        </div>
        <LazyLoadComponent height={16}>
          {(showRole || isVoiceActor) && (
            <div className={css(styles.smallRow)}>
              {showRole && roles.getIn([credit.get("role"), "title"])}
              {isVoiceActor &&
                credit.get("character") &&
                intersperse(
                  credit.get("character").toJS(),
                  (newItem, item, itemIndex) => (
                    <span
                      key={itemIndex}
                      className={css(styles.characterSeparator)}
                    >
                      {" "}
                      •{" "}
                    </span>
                  ),
                  { surround: false }
                )}
            </div>
          )}
        </LazyLoadComponent>
      </div>
    );
  };

  const renderPlayButton = () => (
    <div className={css(styles.cardActions)}>
      <CardActions
        actions={actions}
        entity={episode}
        entity_type="episode"
        podcast={podcast}
      />
    </div>
  );

  if (!episode) {
    console.warn("AppearanceEpisode called without an episode", props);
    return null;
  }

  return (
    <div
      data-id="appearance-episode"
      className={css(
        styles.creatorViewAppearanceEpisode,
        showBorder && styles.withBorder
      )}
    >
      {!hideImage && renderImage()}
      {renderInfo()}
      <LazyLoadComponent height={DESKTOP_ACTIONS_HEIGHT}>
        {renderPlayButton()}
      </LazyLoadComponent>
    </div>
  );
};

CreatorViewAppearanceEpisode.propTypes = {
  credit: PropTypes.instanceOf(Map).isRequired,
  episodeId: PropTypes.number.isRequired,
  showRole: PropTypes.bool,
  showBorder: PropTypes.bool,
  noActions: PropTypes.bool,
  hideImage: PropTypes.bool,
};

CreatorViewAppearanceEpisode.defaultProps = {
  showRole: true,
  showBorder: false,
  noActions: false,
  hideImage: false,
};

export default memo(CreatorViewAppearanceEpisode);
