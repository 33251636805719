import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import CreditSubmissionStub from "components/Entities/Stubs/CreditSubmissionStub";

import useIsModerator from "hooks/useIsModerator";
import useList from "hooks/useList";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  creatorCreditSubmissions: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "5rem",
    padding: "0 1rem 2rem",

    [ScreenSizes.lgAndAbove]: {
      padding: 0,
      marginBottom: "5rem",
    },
  },
};

const titleStyles = {
  titleContainer: {
    ...gStyles.avalonSemiBold,
    margin: "5rem 0 .5rem",

    [ScreenSizes.mdAndAbove]: {
      marginBottom: 0,
      marginTop: "5rem",
      paddingBottom: "1.5rem",
      paddingTop: 0,
    },
  },
  title: {
    fontSize: "1.25rem",
  },
};
const firstTitleStyles = {
  ...titleStyles,
  titleContainer: {
    ...titleStyles.titleContainer,
    margin: "2rem 0 .5rem",

    [ScreenSizes.mdAndAbove]: {
      ...titleStyles.titleContainer[ScreenSizes.mdAndAbove],
      marginTop: "1rem",
    },
  },
};

const CreatorCreditSubmissions = (props) => {
  const { creator, myListKey, moderatorListKey } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isModerator } = useIsModerator();

  const staticFilters = useMemo(
    () => ({
      creator_id: {
        value: creator && creator.get("id"),
      },
    }),
    [creator]
  );

  const { loaded: myLoaded, listCount: myListCount } = useList(myListKey);
  const { loaded: moderatorLoaded, listCount: moderatorListCount } =
    useList(moderatorListKey);

  if (
    myLoaded &&
    myListCount === 0 &&
    (!isModerator || (moderatorLoaded && moderatorListCount === 0))
  ) {
    return null;
  }

  return (
    <div className={css(styles.creatorCreditSubmissions)}>
      <CreditSubmissionStub
        listKey={myListKey}
        title="My Pending Credit Submissions"
        staticFilters={staticFilters}
        entityName="creator"
        entity_type="creator"
        entity={creator}
        titleStyles={firstTitleStyles}
        noLoading
      />
      {isModerator && (
        <CreditSubmissionStub
          listKey={moderatorListKey}
          title="All Pending Credit Submissions"
          staticFilters={staticFilters}
          entityName="creator"
          entity_type="creator"
          entity={creator}
          titleStyles={titleStyles}
          showAllSubmissions
          noLoading
        />
      )}
    </div>
  );
};

CreatorCreditSubmissions.propTypes = {
  myListKey: PropTypes.string.isRequired,
  moderatorListKey: PropTypes.string.isRequired,
  creator: PropTypes.instanceOf(Map),
};
CreatorCreditSubmissions.defaultProps = {
  creator: null,
};

export default CreatorCreditSubmissions;
