import { useCallback } from "react";

import authActionCreators from "actions/auth";
import modalActions from "actions/modals";
import * as authStates from "constants/auth-states";
import { selectAuthLoggedIn } from "selectors/auth";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";

const AccountLinks = () => {
  const user = useLoggedInUser();

  const PROFILE_ACCOUNT_LINKS = [
    {
      title: "Account Settings",
      route: "/profile/settings/account",
      id: "settings",
    },
    user && {
      title: "User Profile",
      route: `/users/${user.get("username")}`,
      id: "profile",
    },
    { title: "Manage my podcast", route: "/profile/claimed", id: "claimed" },
    {
      title: "Manage my credits",
      route: "/profile/creators",
      id: "mycreators",
    },
    { title: "Manage my API", route: "/profile/settings/api", id: "myAPI" },
  ];

  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const { startAuthFlow, showModal } = useActionCreators({
    ...modalActions,
    ...authActionCreators,
  });

  const handleFindFriends = useCallback(
    () => showModal("onboarding", { onboardingStep: "users" }),
    [showModal]
  );

  const handleRegister = useCallback(
    () =>
      startAuthFlow(authStates.REGISTER_FORM, {
        analyticsVariables: { triggered_by: "footerRegister" },
      }),
    [startAuthFlow]
  );

  const handleLogin = useCallback(
    () =>
      startAuthFlow(authStates.LOGIN_FORM, {
        analyticsVariables: { triggered_by: "footerLogin" },
      }),
    [startAuthFlow]
  );

  const handleLoggedOutFindFriends = useCallback(
    () =>
      startAuthFlow(authStates.LOGIN_FORM, {
        entityAction: "action",
        actionTitle:
          "Login or create an account to find your friends on Podchaser",
        analyticsVariables: { triggered_by: "footerFindFriends" },
      }),
    [startAuthFlow]
  );

  if (isLoggedIn) {
    return [
      ...PROFILE_ACCOUNT_LINKS,
      { title: "Find Friends", action: handleFindFriends, id: "findAFriend" },
    ];
  }

  return [
    { title: "Register", action: handleRegister, id: "register" },
    { title: "Log In", action: handleLogin, id: "login" },
    {
      title: "Find Friends",
      action: handleLoggedOutFindFriends,
      id: "findAFriend",
    },
  ];
};

export default AccountLinks;
