import PropTypes from "prop-types";
import { useMemo } from "react";

import RoleGroup from "./RoleGroup";

import { makeSelectSpecificRoleByCode } from "selectors/creator";

import useReduxState from "hooks/useReduxState";

const RoleGroupContainer = (props) => {
  const { roleCode, roleTitle, children, ...rest } = props;

  const selectSpecificRoleByCode = useMemo(makeSelectSpecificRoleByCode, []);
  const role = useReduxState(
    (state) => selectSpecificRoleByCode(state, roleCode),
    []
  );

  if (!role) {
    return null;
  }

  const title = roleTitle
    ? `${role.get("title")} ${roleTitle}`
    : role.get("title");

  return (
    <RoleGroup {...rest} title={title} description={role.get("description")}>
      {children}
    </RoleGroup>
  );
};

RoleGroupContainer.propTypes = {
  children: PropTypes.node,
  roleCode: PropTypes.string,
  roleTitle: PropTypes.string,
};

RoleGroupContainer.defaultProps = {};

export default RoleGroupContainer;
