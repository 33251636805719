import PropTypes from "prop-types";

import ShowMoreContainer from "components/Common/ShowMoreContainer";

const showMoresStyles = {
  outer: {
    paddingBottom: 0,
  },
  outerOpen: {
    paddingBottom: 0,
  },
  hasOverflowContent: {
    cursor: "initial",
  },
};

const GroupShowMore = ({ open, children }) => (
  <ShowMoreContainer
    toggleHeight={0}
    open={open}
    styles={showMoresStyles}
    contentCheckInterval={1000 / 60}
    externalToggle
    checkForContentChanges
    boxShadowsOverflow
    autoOnServer
    blockKeyPressToggle
  >
    {children}
  </ShowMoreContainer>
);

GroupShowMore.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
};

GroupShowMore.defaultProps = {
  children: null,
  open: false,
};

export default GroupShowMore;
