import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faList } from "@fortawesome/pro-solid-svg-icons/faList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import Downshift from "downshift";
import { Link } from "react-router-dom";

import { baseStyles as selectStyles } from "components/Lists/SelectStyles";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  outer: {
    ...selectStyles.outer,
    marginLeft: "1rem",
  },
  input: {
    ...selectStyles.input,
  },
  content: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.7em 0 0.7em 0.5rem",
  },
  icon: {
    fontSize: "0.875rem",
    display: "block",
    maxWidth: "1.25em",
    textAlign: "center",
    flex: "0 0 auto",
    marginRight: "0.5rem",
  },
  option: {
    ...selectStyles.option,
    minWidth: "max-content",
  },
  options: {
    ...selectStyles.options,
    minWidth: "max-content",
  },
  maxContent: {
    width: "max-content",
  },
  toggleIcon: {
    marginRight: "0.5rem",
  },
};

const DEFAULT_VIEW = {
  id: "list",
  to: "?",
  icon: faList,
};

const DEFAULT_VIEWS = [
  DEFAULT_VIEW,
  {
    id: "grid",
    to: "?view=grid",
    icon: faTh,
  },
];

function LayoutSelection(props) {
  const { styles } = useStyles(baseStyles, props);

  return (
    <Downshift
      itemToString={(layout) => layout && layout.id}
      initialSelectedItem={DEFAULT_VIEW}
    >
      {({
        getToggleButtonProps,
        getItemProps,
        isOpen,
        selectedItem,
        highlightedIndex,
        innerRef,
        dataId,
      }) => (
        <div className={css(styles.outer)} ref={innerRef}>
          <button
            className={css(styles.input, styles.maxContent)}
            {...(getToggleButtonProps && getToggleButtonProps())}
            data-testid={dataId}
          >
            <span className={css(styles.content)}>
              <FontAwesomeIcon
                data-testid={selectedItem?.id}
                icon={selectedItem.icon}
                fixedWidth
                className={css(styles.icon)}
              />
            </span>

            <FontAwesomeIcon
              data-testid="dropDown"
              icon={faChevronDown}
              className={css(styles.toggleIcon)}
            />
          </button>
          {isOpen && (
            <div className={css(styles.options)}>
              {DEFAULT_VIEWS.map((option, i) => {
                const selected = selectedItem && option.id === selectedItem.id;
                const highlighted = highlightedIndex === i;

                return (
                  <Link
                    key={option?.id}
                    {...getItemProps({ item: option })}
                    data-testid={`option-${option?.id}`}
                    className={css(
                      styles.option,
                      highlighted && styles.highlightedOption,
                      selected && styles.selectedOption,
                      selected &&
                        highlighted &&
                        styles.selectedHighlightedOption,
                      styles.content
                    )}
                    to={option?.to}
                  >
                    <FontAwesomeIcon
                      icon={option.icon}
                      className={css(styles.icon)}
                      fixedWidth
                    />
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
}

export default LayoutSelection;
