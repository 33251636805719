export const commaListWithMore = (list, cutoff = 3, options = {}) => {
  if (list) {
    const length = list.size || list.length;
    return list.reduce((prev, curr, index) => {
      if (index === 0) {
        return curr;
      }
      if (!curr) {
        return prev;
      }
      // Past the point of needing to show any items or the suffix
      if (index > cutoff) {
        return prev;
      }
      const isFinalInList = index === length - 1;
      const isPastCutoff = index === cutoff;
      const remaining = length - cutoff;
      const strictCutoff = !!options.strictCutoff;
      const showExtraName = !strictCutoff && remaining === 1;

      // See: https://stackoverflow.com/questions/34034038/how-to-render-react-components-by-using-map-and-join

      // Last item in list ("and ___")
      if (isFinalInList && (!isPastCutoff || showExtraName)) {
        return [prev, " & ", curr];
      }
      // The "and more" suffix"
      else if (isPastCutoff) {
        return [prev, ` & ${length - cutoff} more`];
      }
      // Normal item
      return [prev, ", ", curr];
    }, "");
  }

  return "";
};
