import PropTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";

import ImageFallback from "components/Global/Images/ImageFallback";

function IntersectionImage(props) {
  const {
    placeholder,
    src,
    offset,
    alt,
    background,
    imageStyles,
    ...imgProps
  } = props;

  const [imageToShow, setImageToShow] = useState(placeholder);

  const elRef = useRef();

  const io = useRef(
    typeof IntersectionObserver !== "undefined"
      ? new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                entry.target === elRef.current &&
                (entry.isIntersecting || entry.intersectionRatio > 0)
              ) {
                setImageToShow(src);
              }
            });
          },
          { rootMargin: `${offset}px 0px` }
        )
      : null
  );

  useEffect(() => {
    const ioVal = io.current;
    const elVal = elRef.current;
    if (ioVal && elVal) {
      ioVal.observe(elVal);
    }
    return () => {
      if (ioVal && elVal) {
        ioVal.unobserve(elVal);
      }
    };
  }, []);

  if (!imageToShow) {
    return <div {...imgProps} />;
  }

  if (background) {
    return (
      <span
        {...imgProps}
        ref={elRef}
        style={{
          ...imgProps.style,
          display: "block",
          backgroundImage: `url(${imageToShow}`,
          backgroundSize: "cover",
        }}
      />
    );
  }

  return (
    <ImageFallback
      {...imgProps}
      src={imageToShow}
      alt={alt}
      // forwardRef={elRef}
      styles={imageStyles}
    />
  );
}

IntersectionImage.propTypes = {
  placeholder: PropTypes.string,
  src: PropTypes.string.isRequired,
  offset: PropTypes.number,
  alt: PropTypes.string,
  background: PropTypes.bool,
  imageStyles: PropTypes.object,
};

IntersectionImage.defaultProps = {
  placeholder: undefined,
  offset: 200,
  alt: "",
  background: false,
  imageStyles: null,
};

export default memo(IntersectionImage);
