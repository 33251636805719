import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { compose } from "redux";

import NavLink from "components/Common/NavLink";

import generateTransition from "utils/generateTransition";
import sendGAEvent from "utils/sendGAEvent";

import useHover from "hooks/useHover";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const navItemStyles = {
  ...gStyles.avalonBold,
  display: "block",
  padding: "0.688rem 0rem",
  textDecoration: "none",
  color: "#fff",
  opacity: 0.8,
  fontSize: "1rem",
  textTransform: "uppercase",
  height: "100%",
  transition: generateTransition({ target: "visual", speed: "150ms" }),
  paddingLeft: "2.6rem",
  ...getHoverQuery({
    background: colours.secondaryD15,
    opacity: 1,
  }),
};

const baseStyles = {
  navItem: {
    ...navItemStyles,
    borderLeftColor: "#2a0564",
  },
  navItemClosed: {
    opacity: 0.5,
  },

  navItemActive: {
    ...navItemStyles,
    opacity: 1,
    boxShadow: "inset 0 0.1em 0.2em rgba(0,0,0,0.2)",
    ...gStyles.gradientBackground,
    ...gStyles.newBackgroundItemColor,

    ...getHoverQuery({
      ...gStyles.gradientBackground,
      ...gStyles.newBackgroundItemColor,
      color: colours.white,
    }),
  },
  navIcon: {
    fontSize: "1rem",
  },
  navIconClosed: {
    transform: "scale(1,1)",
  },
  navIconHover: {
    transform: "scale(1.2,1.2)",
    textShadow: "0 0.1em 0.2em rgba(0,0,0,0.5)",
  },

  navTitleContainer: {
    transition:
      "transform 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946) 200ms",
    display: "block",
    textTransform: "none",
    letterSpacing: "0.01em",
    alignSelf: "center",
    opacity: 1,
    lineHeight: 1.5,
    verticalAlign: "middle",
    position: "relative",
    height: "calc(100% + 0.688rem)",
    boxSizing: "border-box",
  },
  navTitleContainerClosed: {
    [ScreenSizes.smAndAbove]: {
      opacity: 0,
      transform: "translateX(0)",
    },
  },
  navTitleContainerHover: {
    transform: "translateX(3px)",
    transition:
      "transform 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946)",
  },

  navTitle: {
    ...gStyles.avalonBold,
    display: "block",
    fontSize: "1rem",
    height: "calc(100% + 0.688rem)",
    boxSizing: "border-box",
    paddingLeft: "0.5rem",
  },
  border: {
    content: " ",
    position: "absolute",
    top: "-0.688rem",
    left: "-0.875rem",
    width: "4px",
    height: "calc(100% + 1.375rem)",
    backgroundColor: "#2a0564",
    transform: "translateX(-50%)",
    ":last-child": {
      height: "calc(100% + 0.688rem)",
    },
  },
  borderHover: {
    backgroundColor: "transparent",
  },
  submenu: {
    ...gStyles.avalonBold,
    fontSize: "1rem",
  },
};

const SideSubMenuItems = (props) => {
  const { isOpen, item } = props;
  const [hoverRef, isHovered] = useHover();

  const { styles } = useStyles(baseStyles, props);

  const ItemLink = (item.route && NavLink) || "a";

  const additionalProps = useMemo(
    () => ({
      ...(item.route && {
        to: item.route,
        exact: !!item.exact,
        activeClassName: css(styles.navItemActive),
      }),
      ...((item.type === "external" || item.external) && {
        target: "_blank",
      }),
    }),
    [item, styles.navItemActive]
  );

  const handleItemClick = useCallback(
    (e) => {
      sendGAEvent({
        action: "SideSubMenuItemsClick",
        route: item.route && item.route,
        href: item.href,
        title: item.title,
        hasAction: item.action ? "Yes" : "No",
        external: item.type === "external" || item.external ? "Yes" : "No",
      });
      if (item.action) {
        e.preventDefault();
        item.action();
      }
    },
    [item]
  );

  return item.external ? (
    <a
      className={css(styles.navItem, !isOpen && styles.navItemClosed)}
      target="_blank"
      rel="noopener noreferrer"
      href={item.route || "#"}
      {...additionalProps}
    >
      <span
        className={css(
          styles.navTitleContainer,
          !isOpen && styles.navTitleContainerClosed,
          isHovered && styles.navTitleContainerHover
        )}
      >
        <div
          className={css(styles.border, isHovered && styles.borderHover)}
        ></div>
        <span className={css(styles.navTitle)}>{item.title}</span>
      </span>
    </a>
  ) : (
    <ItemLink
      className={css(styles.navItem, !isOpen && styles.navItemClosed)}
      title={item.title}
      href={item.href || "#"}
      onClick={handleItemClick}
      data-testid={"itemLink"}
      {...additionalProps}
      ref={hoverRef}
    >
      <span
        className={css(
          styles.navTitleContainer,
          !isOpen && styles.navTitleContainerClosed,
          isHovered && styles.navTitleContainerHover
        )}
      >
        <div
          className={css(styles.border, isHovered && styles.borderHover)}
        ></div>
        <span className={css(styles.navTitle)}>{item.title}</span>
      </span>
    </ItemLink>
  );
};

SideSubMenuItems.propTypes = {
  isOpen: PropTypes.bool,
  item: PropTypes.shape({
    route: PropTypes.string,
    type: PropTypes.string,
    exact: PropTypes.bool,
    action: PropTypes.func,
    external: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
    title: PropTypes.node,
    href: PropTypes.string,
  }).isRequired,
};

SideSubMenuItems.defaultProps = {
  isOpen: false,
  item: {},
};

export default SideSubMenuItems;
