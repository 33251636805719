import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faSlack } from "@fortawesome/free-brands-svg-icons/faSlack";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import faXTwitter from "styles/icons/faXTwitter";

const baseStyles = {
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5rem 1.5rem",
    gap: "0.5rem",
  },
  socialIcons: {
    color: "rgb(255, 255, 255)",
    fontSize: "1.625rem",
    fontWeight: "normal",
    textAlign: "center",
    letterSpacing: "0",
    width: "100%",
    height: "3.188rem",
    background: "rgb(63, 4, 103)",
    borderRadius: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    marginLeft: "0.3em",
    paddingLeft: "1em",
    marginRight: "1.7rem",
    paddingRight: "2.2rem",
  },
  seperater: {
    height: "0.063rem",
    background: "rgb(8, 0, 43)",
    borderRadius: "0",
    margin: "0.5rem 1.5rem",
  },
};

const SocialMedia = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <>
      <div className={css(styles.title)}> Say Hello </div>
      <div className={css(styles.contentWrapper)}>
        <a
          href="https://twitter.com/Podchaser"
          className={css(styles.socialIcons)}
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>

        <a
          href="https://www.tiktok.com/@podchaser"
          className={css(styles.socialIcons)}
        >
          <FontAwesomeIcon icon={faTiktok} />
        </a>

        <a
          href="https://join.slack.com/t/podchaser-community/shared_invite/enQtNDE5MjEzNjMyOTM1LTFjMmZmOWNmMmUyMGE3ZDM0NWZiNzYxMGRkZDE5NDUwZjUzYmY2MzAyNGU2MTZhNGVmZjg5YzZlZTNkYzZkNDE"
          className={css(styles.socialIcons)}
        >
          <FontAwesomeIcon icon={faSlack} />
        </a>

        <a
          href="https://www.facebook.com/Podchaser"
          className={css(styles.socialIcons)}
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </div>
      <div className={css(styles.seperater)}></div>
    </>
  );
};

export default SocialMedia;
